



























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoContestModableEntry } from '@/game/infos/dataServerPackets';
import { Queuer } from '@/util/queuer';
import { generateLargeFishImage } from '../mainMenu/tools/largeFishImageGenerator';
import { decodeDecoSet } from '@/game/infos/decorativeInfos';
import fb from '@/store/sf-firestore';

@Component({
  components: {},
})
export default class DecoEntryForVote extends Vue {
  @Prop({ required: true }) public entry!: DecoContestModableEntry;

  public imgSrc = '';
  public isLoading = true;

  @Watch('entry', { immediate: true })
  public async sumcheckChange(newVal: DecoContestModableEntry) {
    if (!newVal || !newVal.fishType) {
      this.imgSrc = '';
      return;
    }
    this.isLoading = true;
    const { fishType, decoSet } = newVal;
    const skinGroup = newVal.skin || 0;
    await Queuer.queue('fishImage', async () => {
      this.imgSrc = await generateLargeFishImage(
        fishType,
        skinGroup,
        decodeDecoSet(decoSet || ''),
      );
      this.isLoading = false;
    });
  }

  public async onClear() {
    if (!this.entry.uid) {
      return;
    }
    try {
      if (!this.entry.cleared) {
        this.entry.cleared = true;
        await fb.actionOnDecoContest(1, this.entry.uid, 'c');
      } else {
        this.entry.cleared = false;
        await fb.actionOnDecoContest(1, this.entry.uid, 'uc');
      }
    } catch (error) {
      this.showError(error);
    }
  }
  public async onDisqualify() {
    if (!this.entry.uid) {
      return;
    }
    try {
      if (!this.entry.disqualified) {
        this.entry.disqualified = true;
        await fb.actionOnDecoContest(1, this.entry.uid, 'dq');
      } else {
        this.entry.disqualified = false;
        await fb.actionOnDecoContest(1, this.entry.uid, 'rq');
      }
    } catch (error) {
      this.showError(error);
    }
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
